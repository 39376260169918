/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import SkipTo from './SkipTo';
import SiteHeader from './SiteHeader'
import SiteFooter from './SiteFooter'
import "../scss/styles.scss"
import ScrollToTop from './ScrollToTop';

const Layout = ({ children, ...props }) => (
  <div className={`main-layout ${props.pageTemplate}`}>
      <SkipTo />
      <SiteHeader  withLogo={true}  />
        <div id="main">
        {children}
        </div>
      <SiteFooter />
      <ScrollToTop />
  </div>
)
export default Layout
