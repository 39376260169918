import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import TextLeftVideoRight from "../components/TextLeftVideoRight";
const HomePage = ({data}) => {
  const page = data.allHomepageJson.nodes[0];
  let pageHeading = page.top_text_heading;
  let stripedHtmlPageHeading =  pageHeading.replace(/<[^>]+>/g, '');

  return (
    <Layout pageTemplate="homepage">
      <Helmet
        htmlAttributes={{
        lang: 'en',
        }}
      >
        <meta charSet="utf-8" />
        <title>MyRetirement | {stripedHtmlPageHeading}</title>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="c71e0e36-b367-4b3d-a1ba-3f53aed3fc7b-test" ></script>
        <script type="text/javascript">
        function OptanonWrapper() { }
        </script>
      </Helmet>
      <section className="homepage-top-content top-blue-section" id="#main">
        <div className="site-container">
          <div className="section-columns">
            <div className="left-col" dangerouslySetInnerHTML={{ __html: page.top_text_heading}} />
            <div className="right-col" dangerouslySetInnerHTML={{ __html: page.top_text_paragraph_text}} />
          </div>
        </div>
      </section>
      <TextLeftVideoRight data={page} />
    </Layout>
  )
}

export const query = graphql`
query MyQuery {
  allHomepageJson{
  nodes {
      post_title
        top_text_heading
        top_text_paragraph_text
        video_section_embedded_video
        video_section_left_column_button {
         video_section_button_item{
					 title
            url
        }
        }
        video_section_left_column_text
        video_section_video_thumbnail {
          alt
          url
        }

    }
  }
}
`


export default HomePage