import React from 'react';
import {Link} from 'gatsby';

import VideoModalTrigger from './VideoModalTrigger';

const TextLeftVideoRight = ({data}) =>(
    <section className="text-left-video-right">
        <div className="site-container">
            <div className="section-columns">
                <div className="left-col">
                    <div className="left-col-text"  dangerouslySetInnerHTML={{ __html: data.video_section_left_column_text}} />
                    <div className="buttons-wrap">
                    {
                        data.video_section_left_column_button.map((button, i) => {
                            if(button.video_section_button_item.url === '#disabled'){
                                return(
                                    <button key={`button-number-${i}`} className="site-button coming-soon" disabled>{button.video_section_button_item.title} <span>(coming soon)</span></button>
                                )
                            } else{
                                return(
                                    <Link key={`button-number-${i}`} className="site-button" to={button.video_section_button_item.url}>{button.video_section_button_item.title}</Link>
                                )
                            }
                            
                        })
                    }
                    </div>
                </div>
                <div className="right-col">
                    <VideoModalTrigger videoThumb={data.video_section_video_thumbnail} videoCode={data.video_section_embedded_video} />
                </div>
            </div>
        </div>
    </section>
    
)

export default TextLeftVideoRight;